import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageLitlSmokies = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/litlsmokies_webresized.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon Wrapped Lit’l Smokies&#174; Recipe"
        metaDescription="Bacon Wrapped Lit'l Smokies® Featuring Wright® Brand Bacon & Hillshire Farm® Lit'l Smokies® Sausages recipe. Spice up your appetizer this holiday!"
        metaKeywords="hot dogs wrapped in bacon"
    >
        <meta property="og:image" content={imageLitlSmokies} />
        <link rel="image_src" href={imageLitlSmokies} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageLitlSmokies} class='img-responsive' alt='LitlSmokiesHolidayLarge' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Wrapped Lit’l Smokies® Sausages</h1>

                            <div class="recipe-content">
                                <p >Bacon Wrapped Lit'l Smokies&reg; Featuring Wright&reg; Brand Bacon &amp; Hillshire Farm&reg; Lit'l Smokies&reg; Sausages</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        15&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>30 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                            1 <span class="uom">Package</span> Wright® Brand Hickory Smoked Bacon           
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                            1 <span class="uom">Package</span> Hillshire Farm® Lit'l Smokies® Sausages          
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> Toothpicks
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat oven to 400°F.
                </p>
                            <p>
                                2. Open the package of Lit'l Smokies® Sausages and drain off any liquid.
                </p>
                            <p>
                                3. Cut each bacon slice into 3 pieces. Wrap each sausage with a piece of bacon; secure with a toothpick.
                </p>
                            <p>
                                4. Place on a 15x10x1-inch baking pan and bake 15-20 minutes, or until bacon is browned.
                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
